<template>
  <div class="signIn_container signIn">
    <div class="signIn_main">
      <img
        :src="require('@/assets/images/login/logo.png')"
        class="signIn_banner"
      />
      <div class="signIn_block">
        <div class="signIn_block_title">签到</div>
        <div class="signIn_info_block">
          <div class="signIn_info_row">
            <div class="signIn_info_row_key">
              <span class="global_tag_require">*</span>姓名:
            </div>
            <div class="signIn_info_row_value">
              <el-input
                clearable
                :class="isShowDoc ? 'signIn_mate_input' : ''"
                v-model.trim="data.name"
                @input="onInput"
                placeholder="请输入姓名"
                @clear="doClear"
              >
              </el-input>
              <div v-if="isShowDoc" class="signIn_mate_block">
                <!-- <div class="signIn_remind">提醒:</div>
              <div class="signIn_remind">
                匹配后无需再完善专家已有信息，请慎重选择！
              </div> -->
                <div
                  class="signIn_mate_doctor"
                  :style="{
                    height: `${
                      config.AllDoctor.length == 0 ? '50px' : '160px'
                    }`,
                  }"
                >
                  <item-scroll ref="ItemScrollDoctor">
                    <div style="">
                      <div
                        class="signIn_mate_doctor_item"
                        v-for="(item, i) in config.AllDoctor"
                        :key="i"
                        @click="selectExpert(item)"
                      >
                        {{ item.name }}-{{ item.hospital }}
                      </div>
                      <div
                        style="color: #b53c93"
                        class="signIn_mate_doctor_item"
                        @click="selectExpert()"
                      >
                        <div class="signIn_mate_doctor_btn">
                          {{
                            config.AllDoctor.length > 0
                              ? "以上均不是我的信息，我要新增"
                              : "暂无该医生信息，请新增"
                          }}
                        </div>
                      </div>
                    </div>
                  </item-scroll>
                </div>
              </div>
            </div>
            <CustomButton
              v-if="false"
              size="small"
              class="match_btn"
              @click="intelligentMatching"
              >智能匹配
            </CustomButton>
          </div>
          <div class="signIn_info_row">
            <div class="signIn_info_row_key">
              <span class="global_tag_require">*</span>省份:
            </div>
            <div class="signIn_info_row_value">
              <el-select
                v-model="data.province"
                placeholder="请选择省份"
                @change="changeProvince()"
              >
                <el-option
                  v-for="(item, index) in provinces"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="signIn_info_row">
            <div class="signIn_info_row_key">
              <span class="global_tag_require">*</span>城市:
            </div>
            <div class="signIn_info_row_value">
              <el-select
                v-model="data.city"
                @change="changeCity"
                placeholder="请选择城市"
              >
                <el-option
                  v-for="(item, index) in citys"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="signIn_info_row">
            <div class="signIn_info_row_key">
              <span class="global_tag_require">*</span>医院:
            </div>
            <div class="signIn_info_row_value">
              <el-input v-model.trim="data.hospital" placeholder="请输入医院">
              </el-input>
            </div>
          </div>

          <div class="signIn_info_row">
            <div class="signIn_info_row_key">
              <span class="global_tag_require">*</span>科室:
            </div>
            <div class="signIn_info_row_value">
              <el-input v-model.trim="data.department" placeholder="请输入科室">
              </el-input>
            </div>
          </div>

          <div class="signIn_info_row">
            <div class="signIn_info_row_key">
              <span class="global_tag_require">*</span>手机号:
            </div>
            <div class="signIn_info_row_value">
              <el-input
                v-model.trim="data.phone"
                @input="changePhone"
                placeholder="请输入手机号"
              >
              </el-input>
            </div>
          </div>
          <div class="signIn_info_row">
            <div class="signIn_info_row_key">
              <span class="global_tag_require">*</span>职务:
            </div>
            <div class="signIn_info_row_value">
              <el-select v-model="data.job_name" placeholder="请选择职务">
                <el-option
                  v-for="(item, index) in config.jobs"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="signIn_info_row">
            <div class="signIn_info_row_key">
              <span class="global_tag_require">*</span>身份证明:
            </div>
            <div class="signIn_info_row_value">
              <div
                class="signIn_info_image-select"
                @click="selectFileImg('identity_proof')"
              >
                <span v-if="identity_proof">
                  {{ identity_proof }}
                </span>
                <span v-else> 点击上传ID卡/官网信息截图 </span>
              </div>
            </div>
          </div>
        </div>
        <div class="signIn_check">
          <el-checkbox v-model="data.readStatus" size="small" />
          <span class="signIn_check_title"
            ><span>我已阅读并接受骨转百讯</span> 《
            <span class="colorRed" @click="goToAgreement('signPrivacy')"
              >隐私政策</span
            >》</span
          >
        </div>
        <div class="signIn_btn">
          <CustomButton
            size="small"
            type="primary"
            class="signIn_submit"
            @click="doCommit"
          >
            进入</CustomButton
          >
        </div>
      </div>
    </div>
    <input
      type="file"
      v-show="false"
      v-if="resetFile"
      @change="changeFileImg"
      ref="selectFileImg"
    />
  </div>
</template>
<script>
import CustomButton from "@/components/unit/CustomButton";
import CustomImage from "@/components/unit/CustomImage.vue";
import uploads from "@/common/uploads";
import ItemScroll from "@/components/unit/ItemScroll";
export default {
  name: "signIn",
  components: {
    CustomButton,
    CustomImage,
    ItemScroll,
  },
  data() {
    return {
      identity_proof: "",
      resetFile: true,
      selectKey: "",
      data: {
        id: 0,
        device_code: "",
        name: "",
        province: "",
        city: "",
        hospital: "",
        device: "",
        version: "",
        width: "",
        height: "",
        department: "",
        job_name: "",
        phone: "",
        readStatus: false,
        identity_proof: "",
      },
      signCount: 0,
      config: {
        AllDoctor: [],
        citys: [],
        hospitals: [],
        jobs: ["主任医师", "副主任医师", "主治医师", "住院医师"],
      },
      loading: false,
      isShowDoc: false,
    };
  },
  computed: {
    provinces() {
      return this.config.citys.map((item) => item.name);
    },
    citys() {
      if (!this.data.province) return [];
      let index = this.provinces.indexOf(this.data.province);
      if (index == -1) return [];

      return this.config.citys[index].sub.map((item) => item.name);
    },
  },
  async created() {
    this.config.citys = await this.$tools.getCityConfig();
    this.loadData();
  },
  methods: {
    loadData() {
      let url = this.$tools.getURL(this.$urls.live.signTime, {});
      this.$axios
        .get(url)
        .then((res) => {
          this.signCount = res.sign_count;
          console.log(this.signCount);
          // if(this.signCount > 4){
          //   this.$tools.setStorage("signInfo","",localStorage)
          // }
          this.initDeviceData();
        })
        .catch((res) => {});
    },

    async initDeviceData() {
      let signInfo =
        this.$store.state.common.signInfo[this.$route.query.id] || {};

      if (!signInfo.openid) {
        let headers = { openid: this.$tools.getCookie("opendid") || "" };
        let tempOpenid = this.$tools.getCookie(
          `${this.$route.query.id}||openid`
        );
        if (tempOpenid) {
          headers.openid = tempOpenid;
        } else {
          headers.openid = this.$tools.getCookie("openid");
        }
        let url = this.$tools.getURL(this.$urls.live.sign, {
          id: this.$route.query.id,
          device_code: encodeURI(
            this.$tools.getStorage("device-code", localStorage) || ""
          ),
        });
        let response = await this.$axios.get(url, headers);
        signInfo = response.data;
        this.identity_proof = signInfo.identity_proof.split("/").pop();
      }
      this.data = {
        ...this.data,
        ...signInfo,
        ...this.$tools.getSystemInfo(),
        ...this.$route.query,
        device_code: this.$tools.getStorage("device-code", localStorage) || "",
      };
      this.data.width = String(this.data.width);
      this.data.height = String(this.data.height);
    },
    selectFileImg(key) {
      this.selectKey = key;

      this.$refs.selectFileImg.click();
    },
    //上传图片(不裁图)
    async changeFileImg(event) {
      let reg = "jpg|jpeg|png|PNG|JPG|JPEG";
      let file = event.target.files[0];
      if (!file) return;
      let RegObj = new RegExp(`^(${reg})$`);
      let type = file.name.split(".").slice(-1);
      if (!RegObj.test(type)) {
        this.$tips.error({ text: `类型必须是${reg.replace(/\|/g, ",")}` });
        return "";
      }
      this.resetFile = false;
      let loading = this.$tips.loading();
      this.$nextTick(() => {
        this.resetFile = true;
      });

      try {
        let res = await uploads.uploadBase64(
          await this.$tools.compressImage(file, true)
        );
        loading.close();
        this.data[this.selectKey] = res;
        console.log(" this.data[this.selectKey] :>> ", res);
        let path1 = res.substr(res.lastIndexOf("/") + 1); //文件名称，去掉路径
        this.identity_proof = path1.substring(0, path1.indexOf("."));
      } catch (err) {
        loading.close();
      }
    },

    changeProvince() {
      this.data.city = "";
      this.data.hospital = "";
    },
    changeCity() {
      this.loadHospital();
    },
    changePhone() {
      this.data.phone = this.data.phone.replace(/\D/g, "").slice(0, 11);
    },
    loadHospital() {
      let data = {
        provine: this.data.province,
        city: this.data.city,
      };
      let url = this.$tools.getURL(this.$urls.all.hospital, data);
      this.$axios.get(url).then((res) => {
        this.config.hospitals = res.data;
      });
    },
    verifyData() {
      if (!this.data.name) {
        this.$tips.error({ text: "请输入姓名" });
        return false;
      }
      if (!this.data.province) {
        this.$tips.error({ text: "请输入省份" });
        return false;
      }
      if (!this.data.city) {
        this.$tips.error({ text: "请输入城市" });
        return false;
      }
      if (!this.data.hospital) {
        this.$tips.error({ text: "请输入医院" });
        return false;
      }
      if (!this.data.department) {
        this.$tips.error({ text: "请输入科室" });
        return false;
      }
      if (!this.data.phone) {
        this.$tips.error({ text: "请输入手机号" });
        return false;
      }
      if (!/^1\d{10}$/.test(this.data.phone)) {
        this.$tips.error({ text: "请输入正确的手机号" });
        return false;
      }
      if (!this.data.job_name) {
        this.$tips.error({ text: "请输入职务" });
        return false;
      }
      if (!this.data.identity_proof) {
        this.$tips.error({ text: "请上传身份证明" });
        return false;
      }
      if (!this.data.readStatus) {
        this.$tips.error({ text: "请阅读并同意隐私协议" });
        return false;
      }
      return true;
    },
    doCommit() {
      if (this.loading || !this.verifyData()) return;
      this.loading = true;
      this.data.id = this.$route.query.id;
      this.$tools.setStorage("signInfo", this.data, localStorage);
      this.$axios
        .post(this.$urls.live.sign, this.data)
        .then((res) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);

          this.$tools.setCookie(
            `${this.$route.query.id}||openid`,
            res.openid,
            6
          );
          this.$store.dispatch("setItem", { signInfo: this.data });
          this.$tools.setStorage("signInfo", "", localStorage);
          this.$router.push({
            path: "/live",
            query: this.$route.query,
          });
        })
        .catch(() => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    goToAgreement(type) {
      this.$tools.setStorage("signInfo", this.data, localStorage);
      this.$router.push({
        path: `/${type}Agreement`,
      });
    },
    onInput(e) {
      if (e.length >= 2) {
        this.intelligentMatching();
      }
      if (e.length == 1) {
        this.isShowDoc = false;
        this.data = { name: e };
        this.identity_proof = "";
      }
      if (e.length == 0) {
        this.doClear();
      }
    },
    doClear() {
      this.data = {};
      this.identity_proof = "";
      this.isShowDoc = false;
    },
    //智能匹配
    intelligentMatching() {
      if (this.data.name.length < 2) {
        this.$tips.error({ text: "请输入专家完整名字" });
        return false;
      }
      let url = this.$tools.getURL(this.$urls.live.matchSignDoctor, {
        doctor_name: this.data.name,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.config.AllDoctor = res.data;
          this.isShowDoc = res.type == "ok" ? true : false;
          setTimeout(() => {
            this.$refs.ItemScrollDoctor.initScroll();
          }, 500);
        })
        .catch((err) => {
          // if (err.msg == "匹配医生次数已达上限" && err.type == "error") {
          //   this.isShowDoc = true;
          // }
        });
    },
    //选择专家(根据智能匹配或者变更专家弹出不同的框)
    async selectExpert(item) {
      if (item) {
        if (!this.config.jobs.includes(item.job_name)) {
          item.job_name = "";
        }
        this.data = { ...this.data, ...item };

        // this.identity_proof = this.data.identity_proof.split("/").pop();
      } else {
        let name = this.data.name;
        this.data = { name };
      }
      this.data.identity_proof = "";
      this.identity_proof = "";
      this.data.phone = "";
      this.data.job_name = "";
      this.isShowDoc = false;
      this.data.readStatus = false;
    },
  },
};
</script>
<style >
.signIn_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #fff8f8, #fde3f0);
  overflow: auto;
}
.signIn_container .el-select {
  width: 100%;
}
.signIn_main {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.signIn_banner {
  width: 10rem;
  object-fit: contain;
  margin-bottom: 0.5rem;
}
.signIn_block {
  width: 90%;
  /* background: #fff; */
  margin: 1rem;
  padding: 1rem 0.5rem;
}
.signIn_block_title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
  color: #e64638;
}
.signIn_info_block_title {
  color: #e64638;
  font-weight: bold;
  margin: 6 0;
  padding: 0.5rem 0;
}

.signIn_info_row {
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
}
.signIn_info_row_key {
  height: 100%;
  width: 4rem;
  font-size: 0.8rem;
  /* text-align: right; */
  margin-right: 1rem;
}
.signIn_info_row_value {
  flex: 1 0 0;
  position: relative;
}
.signIn .el-input__inner {
  border: none;
  background: #fff;
}

.signIn_btn {
  display: flex;
  justify-content: center;
}
.signIn_btn .signIn_submit {
  width: 60%;
  margin: 1rem 0;
  height: 1.333rem;
}
.signIn_check {
  margin: 0.5rem 0;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
}
.signIn_check_title {
  margin-left: 0.25rem;
}
.signIn_info_image-block {
  width: 19rem;
  height: 13.5rem;
  background: #fff;
  border: 1px solid #9f9f9f;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9f9f9f;
}
.signIn_info_image-select {
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  color: #e64638;
  padding: 0.25rem 1rem;
  border-bottom: 1px solid #ccc;
}
.signIn_container .match_btn {
  width: 80px;
  border-radius: 4px;
  padding: 0 !important;
}
.signIn_mate_input {
  border: 1px solid #d94635;
  border-bottom: none;
}
.signIn_mate_block {
  width: 100%;
  padding: 4px;
  position: absolute;
  background: #fff;
  z-index: 999;
  top: 40px;
  right: 0;
  border: 1px solid #d94635;
  border-top: 1px solid #ccc;
  /* border-radius: 8px; */
}
.signIn_remind {
  color: #f26649;
  font-weight: bold;
  line-height: 1.8;
  padding-left: 8px;
}
.signIn_mate_doctor {
  margin-top: 8px;
  width: 100%;
  height: 160px;
}
.signIn_mate_doctor_item {
  /* width: 345px; */
  padding: 0 10px;
  line-height: 1.5;
  /* border-bottom: 1px solid #ccc; */
  display: flex;
  min-height: 40px;
  align-items: center;
}
.signIn_mate_doctor_item:last-child {
  display: flex;
  justify-content: center;
}
.signIn_mate_doctor_btn {
  background: #acc04c;
  color: #fff !important;
  text-align: center;
  border-radius: 2px;
  width: 100%;
}
.signIn_container .el-input__inner {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.signIn_container .el-select:hover:not(.el-select--disabled) .el-input__inner {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.signIn_container .el-select .el-input__inner:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.signIn_container .el-select .el-input.is-focus .el-input__inner {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.signIn_container .el-input__wrapper,
.signIn_container .el-input__wrapper.is-focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.signIn_container .el-input__inner {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.signIn_container .el-select .el-input.is-focus .el-input__wrapper {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>